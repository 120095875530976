@font-face {
  font-family: 'Genty';
  src: url('/public/Genty-Regular.ttf') format('truetype'); 
}

.App {
  min-height: 100vh;
  width: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y pinch-zoom;
}

.content {
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
}

.title-image {
  height: 50px;
  width: auto;
  margin: 8px auto;
  display: block;
  object-fit: contain;
}

.hint-text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  text-align: center;
  margin: 10px auto 5px;
  font-style: italic;
  animation: fadeInOut 3s ease-in-out infinite;
  padding: 0 10px;
}

.subtext-image {
  height: 85px; 
  width: auto;
  margin: 10px auto;
  display: block;
  object-fit: contain;
  transition: transform 0.2s ease;
  cursor: pointer;
  max-width: 85%;
}

.foreground-image {
  width: 95%;
  height: auto;
  object-fit: contain;
  margin: 10px auto;
  display: block;
  position: relative;
}

.counter-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  padding: 15px 0;
  border-radius: 10px;
  gap: 20px; 
}

.counter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.counter-divider {
  width: 80%;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
}

.counter-value {
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0 10px rgba(29, 78, 216, 0.5);
  margin-bottom: 5px;
  font-family: 'Genty', Arial, sans-serif;
}

.counter-label {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.icons-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  padding: 4px;
  width: 100%;
  max-width: 600px;
}

.icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(33.33% - 20px);
  min-width: 60px;
  margin: 0;
}

.custom-icon {
  width: 30px;
  height: 30px;
  object-fit: contain;
  padding: 2px;
  display: block;
  margin: 0 auto;
}

.icon-text {
  font-size: 12px;
  color: white;
  margin-top: 5px;
  text-align: center;
  white-space: nowrap;
}

.copy-feedback {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  animation: fadeInOut 2s ease-in-out;
}

.glow-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 3;
}

.glow-overlay::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  background: radial-gradient(circle, rgba(29, 78, 216, 0.15) 0%, transparent 70%);
  transform: translate(-50%, -50%);
  animation: glowPulse 4s ease-in-out infinite;
}

.icon-link {
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.icon-link:hover {
  transform: scale(1.1);
}

.foreground-link {
  display: block;
  width: 100%;
  text-align: center;
}

.text-container {
  position: relative;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .counter-section {
    flex-direction: column;
    gap: 20px;
  }

  .counter-divider {
    width: 80%;
    height: 1px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) {
  .content {
    padding: 20px;
    justify-content: center;
    max-width: 1200px;
  }

  .title-image {
    height: 120px;
  }

  .hint-text {
    font-size: 14px;
    margin: 15px auto 10px;
  }

  .subtext-image {
    height: 180px; 
    margin: 20px auto;
    max-width: 95%;
  }

  .icons-container {
    gap: 30px;
    margin: 30px auto;
    max-width: 1200px;
  }

  .icon-wrapper {
    width: auto;
    min-width: 80px;
    margin: 0 10px;
  }

  .custom-icon {
    width: 35px;
    height: 35px;
  }

  .icon-text {
    font-size: 14px;
  }

  .counter-section {
    gap: 30px;
  }

  .counter-value {
    font-size: 48px;
    margin-bottom: 8px;
  }

  .counter-label {
    font-size: 16px;
  }

  .foreground-image {
    max-width: 1100px;
    margin: 30px auto;
  }
}

@keyframes glowPulse {
  0% {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (hover: none) {
  .icon-link:active {
    transform: scale(0.95);
  }
  
  .subtext-image:active {
    transform: scale(0.98);
  }
}
